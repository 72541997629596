<template>
  <v-card width="75%" class="ma-auto">
    <member-toolbar />
    <progress-linear :dialog="progress_dialog" />
    <v-card-text>
      <div v-if="ready">
        <!-- BIG SCREEN -->
        <v-row class="hidden-sm-and-down">
          <v-col>
            <v-card-text>
              <div class="">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-file-outline</v-icon>
                  Contract Information
                </h1>
                <!-- FIRST ROW -->
                <v-row class="pt-8">
                  <!-- FIRST COL -->
                  <v-col>
                    <!-- CONTRACT NUMBER -->
                    <v-text-field
                      class="rounded-xl"
                      :rules="rules.required"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-file"
                      v-model="contract.contract_no"
                      color="blue-grey lighten-2"
                      label="Contract Number"
                    />
                    <!-- DATE CREATED -->

                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.date_created"
                      label="Application Date"
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>

                    <!-- END DATE CREATED -->

                    <!-- PLAN TYPE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.plan_type"
                      label="Plan Type"
                      prepend-inner-icon="mdi-flower-outline"
                    ></v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- CONTRACT PRICE -->

                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.contract_price"
                      color="blue-grey lighten-2"
                      label="Contract Price"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                    <!-- BASE VALUE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.base_value"
                      color="blue-grey lighten-2"
                      label="Base Value"
                      prepend-inner-icon="mdi-scale-balance"
                    ></v-text-field>
                    <!-- INSURABLE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="insurable"
                      color="blue-grey lighten-2"
                      label="Insurable"
                      prepend-inner-icon=""
                    ></v-text-field>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- MODE OF PAYMENT -->

                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.mode_of_payment"
                      color="blue-grey lighten-2"
                      label="Mode of Payment"
                      prepend-icon=""
                    ></v-text-field>
                    <!-- MODE OF INSTALLMENT -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.mode_of_installments"
                      color="blue-grey lighten-2"
                      label="Mode of Installment"
                      prepend-icon=""
                    ></v-text-field>

                    <!-- INITIAL PAYMENT -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.initial_payment"
                      color="blue-grey lighten-2"
                      label="Initial Payment"
                      prepend-inner-icon="mdi-currency-usd"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 4th COL -->
                  <v-col>
                    <!-- INSTALLMENT VALUE -->
                    <v-text-field
                      :rules="rules.required"
                      class="text_field rounded-xl"
                      width="150"
                      :class="text_field_padding"
                      v-model="contract.installment_value"
                      color="blue-grey lighten-2"
                      label="Installment Value"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- <br />
          <v-divider max-width="600" ></v-divider> -->
              <div class="pt-8">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-account-outline</v-icon>
                  Personal Information
                </h1>
                <!-- SECOND ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col cols="4">
                    <!-- CUSTOMER NAME -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_name"
                      color="blue-grey lighten-2"
                      label="Customer Name"
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                    <!-- CUSTOMER ADDRESS -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_address"
                      color="blue-grey lighten-2"
                      label="Customer Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- CUSTOMER BUSINESS ADDRESS -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_business_address"
                      color="blue-grey lighten-2"
                      label="Customer Business Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- CUSTOMER BIRTHDATE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_birthdate"
                      label="Customer Birthdate"
                      prepend-inner-icon="mdi-cake"
                    >
                    </v-text-field>

                    <!-- END CUSTOMER BIRTHDATE -->
                    <!-- CIVIL STATUS -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_civil_status"
                      color="blue-grey lighten-2"
                      label="Civil Status"
                      prepend-inner-icon="mdi-gender-male-female"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col cols="4">
                    <!-- GENDER -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_gender"
                      color="blue-grey lighten-2"
                      label="Gender"
                      prepend-inner-icon="mdi-gender-male-female"
                    ></v-text-field>

                    <!-- RELIGION -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_religion"
                      color="blue-grey lighten-2"
                      label="Customer Religion:"
                      prepend-inner-icon="mdi-church"
                    ></v-text-field>

                    <!-- CUSTOMER ANNUAL INCOME -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_annual_income"
                      color="blue-grey lighten-2"
                      label="Customer Annual Income"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                    <!-- CUSTOMER TIN -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_tin"
                      color="blue-grey lighten-2"
                      label="Customer TIN"
                      prepend-inner-icon="mdi-barcode"
                    ></v-text-field>
                    <!-- CUSTOMER SSS -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_sss"
                      color="blue-grey lighten-2"
                      label="Customer SSS"
                      prepend-inner-icon="mdi-barcode"
                    ></v-text-field>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col cols="4">
                    <!-- CUSTOMER TEL -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_tel"
                      color="blue-grey lighten-2"
                      label="Customer Telephone Number"
                      prepend-inner-icon="mdi-phone"
                    >
                    </v-text-field>
                    <!-- CUSTOMER MOBILE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_mobile"
                      color="blue-grey lighten-2"
                      label="Customer Mobile Number"
                      prepend-inner-icon="mdi-cellphone-basic"
                    ></v-text-field>
                    <!-- CUSTOMER EMAIL -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_email"
                      color="blue-grey lighten-2"
                      label="Customer Email"
                      prepend-inner-icon="mdi-email"
                    ></v-text-field>
                    <!-- CUSTOMER OCCUPATION -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_occupation"
                      color="blue-grey lighten-2"
                      label="Customer Occupation"
                      prepend-inner-icon="mdi-briefcase-outline"
                    ></v-text-field>
                  </v-col>
                  <!-- 4th COL  -->
                  <!-- <v-col cols="3"> </v-col> -->
                </v-row>
              </div>

              <div class="pt-8">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-account</v-icon>
                  Payor's Information
                </h1>
                <!-- THIRD ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col>
                    <!-- SAME PAYOR? -->

                    <!-- PAYOR RELATION -->

                    <v-text-field
                      v-if="this.contract.payor_relation"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_relation"
                      color="blue-grey lighten-2"
                      label="Payor Relation"
                      prepend-inner-icon=""
                    ></v-text-field>
                    <!-- PAYOR NAME -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_name"
                      color="blue-grey lighten-2"
                      label="Payor Name"
                      prepend-inner-icon="mdi-account-outline"
                    ></v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- PAYOR ADDRESS -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_address"
                      color="blue-grey lighten-2"
                      label="Payor Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- PAYOR BIRTHDATE -->

                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_birthdate"
                      label="Payor Birthdate"
                      prepend-inner-icon="mdi-cake"
                    >
                    </v-text-field>

                    <!-- PAYOR CIVIL STATUS -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_civil_status"
                      color="blue-grey lighten-2"
                      label="Payor Civil Status"
                      prepend-inner-icon=""
                    ></v-text-field>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- PAYOR GENDER -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_gender"
                      color="blue-grey lighten-2"
                      label="Payor Gender"
                      prepend-inner-icon="mdi-gender-male-female"
                    >
                    </v-text-field>

                    <!-- PAYOR TEL -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_tel"
                      color="blue-grey lighten-2"
                      label="Payor Telephone Number"
                      prepend-inner-icon="mdi-phone"
                    >
                    </v-text-field>
                    <!-- PAYOR MOBILE -->
                    <v-text-field
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_mobile"
                      color="blue-grey lighten-2"
                      label="Payor Mobile Number"
                      prepend-inner-icon="mdi-cellphone-basic"
                    ></v-text-field>
                  </v-col>
                  <!-- 4th COL -->
                  <v-col>
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_email"
                      color="blue-grey lighten-2"
                      label="Payor Email"
                      prepend-inner-icon="mdi-email"
                    ></v-text-field>
                  </v-col>
                  <!-- PAYOR EMAIL -->
                </v-row>
              </div>
              <div class="pt-8">
                <h1 class="display-1 font-weight-thin ml-4">
                  <v-icon x-large class="mr-2">mdi-account-group</v-icon>
                  Beneficiaries
                </h1>
                <!-- FOURTH ROW -->
                <v-row class="pt-5">
                  <v-col>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Beneficiary ID</th>
                          <th>Contract ID</th>
                          <th>Name</th>
                          <th>Birth Date</th>
                          <th>Relationship</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="bene in contract.contract_beneficiaries"
                          :key="bene.id"
                        >
                          <td>{{ bene.id }}</td>
                          <td>{{ bene.contract_id }}</td>
                          <td>{{ bene.name }}</td>
                          <td>{{ bene.birth_date }}</td>
                          <td>{{ bene.relationship }}</td>
                          <td>{{ bene.address }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <div class="pt-8">
                <!-- FIFTH ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col>
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.signed_date"
                      label="Signed Date"
                      prepend-inner-icon="mdi-calendar"
                    ></v-text-field>

                    <!-- END SIGNED DATE -->
                    <!-- ENCODER -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.encoder"
                      color="blue-grey lighten-2"
                      label="Encoded by:"
                      prepend-inner-icon="mdi-typewriter"
                    ></v-text-field>
                    <!-- ENCODED DATE -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.encoded_date"
                      color="blue-grey lighten-2"
                      label="Encoded Date:"
                      prepend-inner-icon="mdi-calendar"
                    ></v-text-field>
                    <!-- Verified By -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.verified_by"
                      color="blue-grey lighten-2"
                      label="Verified by:"
                      prepend-inner-icon="mdi-check"
                      readonly
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- SIGNED PLACE -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.signed_address"
                      color="blue-grey lighten-2"
                      label="Signed Place:"
                      prepend-inner-icon=""
                    ></v-text-field>
                    <!-- FAMILY ADVISER -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.family_adviser"
                      color="blue-grey lighten-2"
                      label="Family Adviser:"
                      prepend-inner-icon=""
                    ></v-text-field>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- BUSINESS MANAGER -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.business_manager"
                      color="blue-grey lighten-2"
                      label="Business Manager"
                      prepend-inner-icon="mdi-account-tie"
                    >
                    </v-text-field>
                    <!-- AGENTS -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="agent"
                      color="blue-grey lighten-2"
                      label="Agent"
                      prepend-inner-icon="mdi-account-group"
                    ></v-text-field>
                    <!-- BRANCHES -->
                    <v-text-field
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.branch.branch_name"
                      color="blue-grey lighten-2"
                      label="Branch"
                      prepend-inner-icon="mdi-domain"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <!-- PAYMENTS -->
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import MemberToolbar from "./MemberToolbar.vue";
import ProgressLinear from "../ProgressLinear.vue";
export default {
  components: { MemberToolbar, ProgressLinear },
  data() {
    return {
      text_field_padding: "pr-2 pl-2",
      dialog: false,
      color: "grey",
      input_text_color: "",
      title: "",
      date_created_modal1: false,
      date: "",
      ready: false,
      progress_dialog: true,
      show_alert: false,
      alert_message: "",
      payor_disabled: false,
      rules: {
        required: [(value) => !!value || "Field is required"],
      },
      beneficiary_headers: [
        { text: "id", value: "id" },
        { text: "Contract ID", value: "contract_id" },
      ],
      same_payor: [
        { val: "1", text: "Yes" },
        { val: "0", text: "No" },
      ],
      formatted_agents: "",
    };
  },
  async created() {
    this.progress_dialog = true;

    var request = {
      contract_no: this.$route.params.contract_no,
      contract_id: this.$route.params.contract_id,
    };
    if (!this.contract) await this.get_member_contracts(request);

    this.ready = true;
    this.progress_dialog = false;
  },
  methods: {
    ...mapActions({
      get_member_contracts: "member/get_member_contract",
    }),
  },
  computed: {
    ...mapGetters({
      contract: "member/member_contract",
    }),
    insurable() {
      return this.contract.insurable == 1 ? "Yes" : "No";
    },
    agent() {
      return this.contract.agent.name + " " + this.contract.agent.surname;
    },
  },
};
</script>

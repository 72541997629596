<template>
  <v-card width="75%" class="ma-auto">
    <member-toolbar />
    <progress-linear :dialog="progress_dialog" />
    <v-card-text>
      <div class="container pa-5" v-if="ready">
        <h2 class="mb-5">Payment History</h2>
        <v-data-table :headers="header" :items="payments" dense> </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import MemberToolbar from "./MemberToolbar.vue";
import ProgressLinear from "../ProgressLinear.vue";
export default {
  components: { MemberToolbar, ProgressLinear },

  data() {
    return {
      ready: false,
      payments_array: [],
      progress_dialog: false,
      counter: 0,
      header: [
        {
          text: "No.",
          value: "installment_number",
          filterable: false,
        },
        {
          text: "Payment ID",
          value: "id",
          filterable: false,
        },
        {
          text: "Payment Date",
          value: "payment_date",
          filterable: false,
        },
        {
          text: "Amount Paid",
          value: "amount_paid",
          filterable: false,
        },
        {
          text: "PR Number",
          value: "pr_number",
          filterable: false,
        },

        {
          text: "Posted by",
          value: "posted_by",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      payments: "member/member_payments",
    }),
  },

  async created() {
    this.progress_dialog = true;
    var contract_id = this.$route.params.contract_id;
    if (!this.payments) await this.get_member_payments(contract_id);
    this.ready = true;
    this.progress_dialog = false;
  },
  methods: {
    ...mapActions({
      get_member_payments: "member/get_member_payments",
    }),
    convert_payment_date(date) {
      var payment_date = moment(date);
      return payment_date.format("MMM DD, YYYY");
    },
  },
};
</script>

<style></style>

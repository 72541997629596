<template>
  <v-toolbar dark flat color="blue" height="150">
    <v-toolbar-title>
      <div class="row">
        <div class="col-2">
          <v-img src="@/assets/mata_logo.png" width=""></v-img>
        </div>
        <div class="col">
          <span class="display-1 font-weight-thin">Member Dashboard</span>
          <br /><span class="overline"> Mata Funeral Homes</span>
        </div>
      </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn @click="contract_profile()" small color="yellow black--text"
      >Contract Profile</v-btn
    >
    <v-btn @click="payment_history()" small outlined class="ml-2"
      >Payment History</v-btn
    >
    <v-btn @click="logout()" small class="ml-2" color="" outlined>Logout</v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      clear_vuex: "member/clear_vuex",
    }),
    contract_profile() {
      var contract_no = this.$route.params.contract_no;
      var contract_id = this.$route.params.contract_id;

      this.$router.push(
        "/member/contract_profile/" + contract_no + "/" + contract_id
      );
    },
    payment_history() {
      var contract_no = this.$route.params.contract_no;
      var contract_id = this.$route.params.contract_id;

      this.$router.push(
        "/member/payment_history/" + contract_no + "/" + contract_id
      );
    },
    logout() {
      this.clear_vuex();
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

<template>
  <v-container class="mt-15">
    <v-container class="fill-height mt-13" fluid>
      <v-row align="center" justify="center">
        <v-col cols="13" sm="8" md="8">
          <v-card class="elevation-12 rounded-l" max-width="">
            <v-row>
              <v-col>
                <!-- MEMBER LOGIN -->
                <div class="mr-11 ml-5 text-center">
                  <h2 class="blue--text mb-4 mt-4">Member Login</h2>

                  <v-form ref="form">
                    <v-text-field
                      class="rounded-xl"
                      prepend-icon="mdi-clipboard-list"
                      label="Contract Number"
                      placeholder="Enter your contract number"
                      filled
                      v-model="member_form.contract_no"
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      class="rounded-xl"
                      prepend-icon="mdi-lock-outline"
                      label="Code"
                      placeholder="Enter your password"
                      filled
                      v-model="member_form.contract_id"
                    ></v-text-field>
                  </v-form>
                  <v-btn
                    rounded
                    elevation="2"
                    color="primary darken-4"
                    class="mr-2"
                    @click="submit_member()"
                    :loading="loading"
                    >Login</v-btn
                  >

                  <v-btn rounded elevation="2" color="yellow" @click="reset"
                    >Reset</v-btn
                  >

                  <p class="caption mt-9 grey--text">
                    Note: Please enter your contract number and code sent from
                    mata online portal text message.
                  </p>
                  <p class="caption primary--text mt-5"></p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <p class="caption grey--text text--lighten-0 mt-10 text-center">
            Powered by: JMBComputers IT Solutions | Version 2.3 | 2-25-22
            <br />
            <v-icon color="white" small class="mr-2">mdi-gmail</v-icon
            >janmichaelbesing873@gmail.com
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      user_signin: false,
      form: {
        username: "",
        password: "",
      },
      member_form: {
        contract_id: "",
        contract_no: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      member_signin: "contract/member_signin",
      set_notification: "notification/set_notification",
    }),
    reset() {
      (this.form.username = ""), (this.form.password = "");
    },
    submit_member() {
      this.loading = true;
      this.member_signin(this.member_form)
        .then(() => {
          this.loading = false;
          this.$router.push(
            "/member/" +
              this.member_form.contract_no +
              "/" +
              this.member_form.contract_id
          );
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.loading = false;
            this.set_notification({
              message: "Username or Password Incorrect. Please try again.",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          } else {
            this.loading = false;
            this.set_notification({
              message: "Something went wrong. Please contact the administrator",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          }
        });
    },
  },
};
</script>
<style></style>
